/**
 * 
 * This component may not be reversed engineered for hacking or abuse
 * of The EGT Universe, The Universe, or third-party apps.
 * 
 * Written for:
 * Universe App Tools.
 * 
 * Justin K Kazmierczak
 * 
 * Saves and retrieves files that are stored in the manager.
 * 
 * The manager module could be replaced to work with cloud files like native files.
 * 
 */

// var jckConsole = require("@jumpcutking/console");
var GenerateSafeError = require("./functions/generateSafeError.js").function;
var namespace = "uam.filemanager";
var resolve = require("./functions/resolve.js").function;
// var resolvable = require("./functions/resolvable.js").function;
var padZeros = require("./functions/padZeros.js").function;
var fs = require("fs");
var path = require("path");

/**
 * Saves a JSON object to the file manager.
 * @param {*} uri File path
 * @param {*} json Object to store
 */
function saveJSON(uri, json, replacer = null, spacing = 2) {

    uri = resolve(uri);

    //if the folder directory doesn't exsits create it recursively
    var dir = uri.split("/");
    dir.pop();
    dir = dir.join("/");
    if (!fs.existsSync(dir)) {
        fs.mkdirSync(dir, { recursive: true });
    }

    fs.writeFileSync(uri, JSON.stringify(json, replacer, spacing)) // spacing level = 2)

} module.exports.saveJSON = saveJSON;

/**
 * Copies a file from the source to the destination.
 * @param {String} _uri The path to the file. Will resolve.
 * @param {String} _destUri The path to the destination. Will resolve. 
 * @param {Boolean} overide Overide the file if it already exists. Default is false.
 * @returns {String} The fully qualified path to the new file.
 */
function copy(_uri, _destUri, overide = false) {
    
    var uri = resolve(_uri);
    var destUri = resolve(_destUri);


    //does the soure _uri exist?
    if (!fs.existsSync(uri)) {
        throw new Error(`Source file does not exist: ${uri}`);
    }

    if (fs.existsSync(destUri)) {
        if (overide == false) {
            throw new Error(`Destination file already exists: ${destUri}`);
        }
    }

    //get the full directory path
    var dir = destUri.split("/");
    dir.pop();
    dir = dir.join("/");
    

    //copy the file
    fs.copyFileSync(uri, destUri);

    return destUri;

} module.exports.copy = copy;

/**
 * Joins paths together - without resolving.
 * If you want to resolve and join multiple paths, use resolve. (First path only).
 * @param  {...any} args 
 * @returns Uses path.join
 */
function join(...args) {
    return path.join(...args);
} module.exports.join = join;

/**
 * Resloves a path based on the APP as the home directory. Uses path.join on additional arguments.
 * @! => process.cwd()
 * 
 * @!/functions will equal /myapp path.join with "./functions"
 * /myapp/functions
 * @param {*} path The path to resolve. Path.Join arguments
 * @returns an adjusted path
 */
module.exports.resolve = resolve;

/**
 * Using the @! directory path, resolves a path from a fully qualified uri to a path relative to that directory.
 * @param {*} uri The fully qualified path to the file.
 * @param  {...any} _path The path to resolve.
 * @returns The resolved path.
 */
function ResolveFrom(uri, ..._path) {

    //if the tilda is in the uri
    if (uri.indexOf("~") != -1) {
        throw new Error("The uri contains a ~. URI ");
    }

    //for each path remove @!/
    for (var i = 0; i < _path.length; i++) {
        _path[i] = _path[i].replace("@!", "");
        // _path[i] = _path[i].replace("~", "");
    }

    return path.join(uri, ..._path);
} module.exports.ResolveFrom = ResolveFrom;

/**
 * Does the file exist?
 * @param {*} uri The path to the file.
 * @returns Does the file exist? Boolean. returns fs.existsSync(uri);
 */
function exists(uri) {
    uri = resolve(uri);
    return fs.existsSync(uri);
} module.exports.exists = exists;

/**
 * Creates a directory.
 * @param {*} uri The path to the directory.
 * @returns {Boolean} True if the directory was created. Returns fs.mkdirSync(uri, { recursive: true });
 */
function createDirectory(uri) {
    uri = resolve(uri);
    return fs.mkdirSync(uri, { recursive: true });
} module.exports.createDirectory = createDirectory;

/**
 * Checks to see if a directory exists, if not, creates it.
 * @param {*} uri The path to the directory.
 * @returns {Boolean} True if the directory was created. Returns createDirectory(uri);
 */
function createDirectoryIfNotExists(uri) {
    uri = resolve(uri);
    if (!exists(uri)) {
        return createDirectory(uri);
    }
} module.exports.createDirectoryIfNotExists = createDirectoryIfNotExists;

/**
 * Clears the contents of a directory.
 * @param {*} uri The path to the directory.
 */
function emptyDirectory(uri) {
    
        uri = resolve(uri);

        //ensure it ends with a "/"
        if (uri[uri.length - 1] != "/") {
            uri = uri + "/";
        }

        fs.rmSync(uri, { recursive: true });
        // fs.rmSync(uri, { recursive: true });

        //recreate the directory
        createDirectory(uri);
    
} module.exports.emptyDirectory = emptyDirectory;

// /**
//  * Copy and overwrite a file.
//  * @param {*} src the URI to the file
//  * @param {*} dest the URI to the destination
//  * @param {*} overwrite overwrite the file if it already exists
//  */
// function copy(src, dest, overwrite = false) {
//     src = resolve(src);
//     dest = resolve(dest);

//     // if (!fs.existsSync(src)) {
//     //     throw new Error(`Source file does not exist: ${src}`);
//     // }

//     if (fs.existsSync(dest)) {
//         if (overwrite == false) {
//             throw new Error(`Destination file already exists: ${dest}`);
//         }
//     }

//     fs.copyFileSync(src, dest);

// } module.exports.copy = copy;

/**
 * Changes the file extension of a path.
 * @param {*} uri The uri to change the extension of.
 * @param {*} ext The new extension.
 * @returns The uri with the new extension.
 */
function ChangeFileExt(uri, ext) {

    if (ext[0] == ".") {
        ext = ext.substr(1);
    }

    return RemoveFileExt(resolve(uri)) + `.${ext}`;
} module.exports.ChangeFileExt = ChangeFileExt;

/**
 * Removes the file extension from a path.
 * @param {*} uri The uri to remove the extension from.
 * @returns The uri without the extension.
 */
function RemoveFileExt(uri) {
    var pos = uri.lastIndexOf(".");
    return uri.substr(0, pos < 0 ? uri.length : pos);
} module.exports.RemoveFileExt = RemoveFileExt;

/**
 * Joins a URL together.
 * Maintians the :// and replaces \ with /
 */
function joinURL(...args) {

    var uri = path.join(...args);

    //replace \ with /
    uri = uri.replace(/\\/g, "/");
    uri = uri.replace(/:\//g, "://");

    return uri;

} module.exports.joinURL = joinURL;

/**
 * Loads and parses a JSON file.
 * @param {*} uri file path
 */
function loadJSON(uri) {

    uri = resolve(uri);
    //load the config
    return JSON.parse(fs.readFileSync(uri, 'utf8').toString());

} module.exports.loadJSON = loadJSON;

/**
 * Loads any file with a UTF8 encoding/
 * @param {*} uri The path to the file.
 * @param {*} encoding The encoding to use. Default is UTF8.
 * @returns The file contents.
 * @throws Error if the file doesn't exist.
 */
function load(uri, encoding = "utf8") {
    uri = resolve(uri);
    return fs.readFileSync(uri, encoding).toString();
} module.exports.load = load;

/**
 * Does the log folder exist? If not, create it.
 * @returns {String} The path to the log folder.
 */
function GetLogFolder(folder = "", logFilePath = false) {


    //does the log folder exist?
    createDirectoryIfNotExists(resolve("@!/.log"));

    //correct the folder with a closing "/"
    folder = correctFolder(folder);

    //create the log folder if it doesn't exist
    if (logFilePath == false) {
        logFilePath = path.join(__dirname,`../.log/${folder}`);
    }

    createFolderRecursively(logFilePath, path.join(__dirname, "../.log"));

    // console.info ("Log folder: ", logFilePath);
    return logFilePath;
} module.exports.GetLogFolder = GetLogFolder;

/**
 * Saves a JSON object to the file manager.
 * @param {*} uri File path
 * @param {*} data text or binary data to store
 */
function save(uri, data) {

    uri = resolve(uri);

    //if the folder directory doesn't exsits create it recursively
    var dir = uri.split("/");
    dir.pop();
    dir = dir.join("/");
    if (!fs.existsSync(dir)) {
        fs.mkdirSync(dir, { recursive: true });
    }

    fs.writeFileSync(uri, data) // spacing level = 2)

} module.exports.save = save;


/**
 * Resolves and compares a from and a to, returning path.relative
 * @param {*} from The from URI
 * @param {*} to The to URI
 * @returns The relative path.
 */
function relative(from, to) {
    return path.relative(resolve(from), resolve(to));
} module.exports.relative = relative;

// /**
//  * Creates a path that can be resolved from the app root using the current app root.
//  * @! => process.cwd()
//  * @!/example.html will equal /my/home/my/app/path/example.html
//  * @param {*} path The path to resolve.
//  * @returns an adjusted path. Example: @!/example.html
//  * @deprecated Use resolve instead.
//  */
// function resolveable(...args) {
//     return resolvable(...args);
// } module.exports.resolveable = resolveable;

/**
 * Creates a folder recursively, but limits it to the starting point
 * @param {*} folder the name of the folder
 * @param {*} limitStartingPoint the starting point - do not end in a "/"
 */
function createFolderRecursively(folder, limitStartingPoint) {

    // console.log("Creating folder recursively...", {
    //     foldr: folder,
    //     limit: limitStartingPoint
    // });

    if (folder === limitStartingPoint) {
    
    //   console.info("Reached starting point.", {
    //     test: (folder === limitStartingPoint)
    //   });
      // Reached the starting point, start creating folders from here
      createFolder(folder);
    } else {
      // Split the folder path into parts
      var parts = folder.split('/');

      var ReachedStartingPoint = false;
      
      // Recursively create parent folders
      for (let i = 1; i < parts.length; i++) {

        var parentFolder = parts.slice(0, i).join('/');
        var curFolder = parts.slice(0, i + 1).join('/');

        // console.log("Parent folder: ", {
        //     parnt: parentFolder,
        //     currt: curFolder,
        //     limit: limitStartingPoint,
        //     ReachedStartingPoint: ReachedStartingPoint
        // });
        
        if (parentFolder === limitStartingPoint) {
          // Reached the starting point, start creating folders from here
          ReachedStartingPoint = true
        }

        if (ReachedStartingPoint) {
            createFolder(curFolder);
        }

      }
    }
 }
  
/**
 * Creates a folder if it doesn't exist.
 * @param {*} folder The folder to create.
 */
function createFolder(folder) {
    if (!fs.existsSync(folder)) {
        // The folder does not exist, create it
        fs.mkdirSync(folder);
        // console.log(`Created folder: ${folder}`);
    }
}

/**
 * Get's the backup date in the format of YYYYMMDD-HHMMSS
 * @returns {String} The backup date string.
 */
function getBackupDate() {
    var date = new Date();
    // console.log("Date test.", {
    //     year: date.getFullYear(),
    //     month: date.getMonth(),
    //     date: date.getDate(),
    //     hours: date.getHours(),
    //     minutes: date.getMinutes(),
    //     seconds: date.getSeconds(),
    //     withPadded: date.getFullYear() + padZeros(date.getMonth(), 2) + padZeros(date.getDate(), 2) + "-" + padZeros(date.getHours(), 2) + padZeros(date.getMinutes(), 2) + padZeros(date.getSeconds(), 2)
    // });
    return date.getFullYear() + padZeros(date.getMonth(), 2) + padZeros(date.getDate(), 2) + "-" + padZeros(date.getHours(), 2) + padZeros(date.getMinutes(), 2) + padZeros(date.getSeconds(), 2);
    // return dateStr;
} module.exports.getBackupDate = getBackupDate;

/**
 * Saves a backup of the file.
 * @param {*} uri The fully qualified path to the file.
 * @param {*} folder The folder to save the backup to or save in root of the .log file
 */
function SaveBackupOf(uri, folder = "", logFilePath = false) {
    
    var logPath = GetLogFolder(folder, logFilePath);
    // folder = correctFolder(folder);
    // if (folder != "") {
    //     folder = folder + "/";
    // }

    var fileNameAndExt = path.basename(uri);
    var fileContents = fs.readFileSync(uri);
    fs.writeFileSync(path.join(logPath, `/${getBackupDate()} ${fileNameAndExt}`), fileContents);

} module.exports.SaveBackupOf = SaveBackupOf; module.exports.Backup = SaveBackupOf;

/**
 * Saves a log file to ./log folder of the app
 * @param {*} filename The name of the file (without the extension or path).
 * @param {*} json The object to save.
 * @param {*} folder The folder to save the backup to or save in root of the .log file
 * @param {*} logFilePath The path to the log folder. If false, it will use the default path.
 * @returns {String} The fully qualified path to the file.
 */
function SaveLogJSON(filename, json, folder = "", logFilePath = false) {  

    // folder = correctFolder(folder);
    var logPath = GetLogFolder(folder, logFilePath);

    var uri = path.join(logPath, `/${filename}.json`);
    saveJSON(uri, json);

    return uri;

} module.exports.SaveLogJSON = SaveLogJSON;

/**
 * Loads a log file from the log folder of the app/
 * @param {*} filename The name of the file (without the extension or path).
 * @param {*} folder The folder to save the backup to or save in root of the .log file
 * @param {*} logFilePath The path to the log folder. If false, it will use the default path.
 * @returns {object} The JSON object.
 */
function LoadLogJSON(filename, folder = "", logFilePath = false) {
    
        // folder = correctFolder(folder);
        var logPath = GetLogFolder(folder, logFilePath);
    
        var uri = path.join(logPath, `/${filename}.json`);
        return loadJSON(uri);
    
} module.exports.LoadLogJSON = LoadLogJSON;

/**
 * Saves a log file to log folder of the app
 * @param {*} filename The name of the file (without the extension or path).
 * @param {*} json The object to save.
 * @param {*} folder The folder to save the backup to or save in root of the .log file
 * @param {*} logFilePath The path to the log folder. If false, it will use the default path.
 * @returns {String} The fully qualified path to the file.
 */
function BackupJSON(filename, json, folder = "", logFilePath = false) {

    //add date and time to filename
    filename = `${filename} ${getBackupDate()}`;
    return SaveLogJSON(filename, json, folder, logFilePath);

} module.exports.BackupJSON = BackupJSON;

/**
 * Removes log files (recursively) that are older than the specified number of days.
 * @param {*} days The number of days to keep the log files.
 * @returns {Array} An array of files that were deleted.
 */
function PruneLog(days) {



    var logDir = GetLogFolder();
    var now = new Date();
    var cutoff = now.setDate(now.getDate() - days);

    console.log(`Prunning ${logDir} for files older than ${days} days.`);

    var info = PruneAllFilesInDir(logDir, cutoff);

    //calculate the total number of bytes deleted
    var totalBytes = 0;
    for (var i = 0; i < info.deleted.length; i++) {
        totalBytes += info.deleted[i].size;
    }

    console.log(`Pruned ${info.deleted.length} log files, with ${info.errors.length} errors and saved ${totalBytes}b.`);
    SaveLogJSON("prune.log.json", info);

    return info;

} module.exports.PruneLog = PruneLog;


/**
 * Prunes all files in a directory recursively.
 * @param {*} dir The directory to prune.
 * @param {*} cutoff The cutoff Date.
 * @returns {Object} An object with the following properties:
 */
function PruneAllFilesInDir(dir, cutoff) {

    var files = fs.readdirSync(dir);

    console.log(`Checking ${files.length} files in ${dir}`);

    var info = {
        errors: [],
        deleted: []
    }

    //for each file detect if it is a directory
    for (var i = 0; i < files.length; i++) {

        var filePath = path.join(dir, files[i]);
        var stats = fs.statSync(filePath);
        var fileAge = new Date(stats.mtime).getTime(); // Use last modified time
        var fileSize = stats.size;

        // is it a directory
        if (stats.isDirectory()) {
            //recurse
            try {

                if (PruneEmptyFolder(filePath)) {
                    info.deleted.push({
                        path: filePath,
                        size: 0
                    });
                    continue;
                }

                var rtn = PruneAllFilesInDir(filePath, cutoff);

                if (rtn.errors.length > 0) {
                    if (info.errors > 0) {
                        info.errors = info.errors.concat(rtn.errors, info.errors);
                    } else {
                        info.errors = rtn.errors;
                    }
                }

                if (rtn.deleted.length > 0) {
                    if (info.deleted > 0) {
                        info.deleted = info.deleted.concat(rtn.deleted, info.deleted);
                    } else {
                        info.errors = rtn.deleted;
                    }
                }

                if (PruneEmptyFolder(filePath)) {
                    info.deleted.push({
                        path: filePath,
                        size: 0
                    });
                    continue;
                }

            } catch (error) {
                error = GenerateSafeError(error);
                console.warn(`Error deleting file ${filePath}`, error);
                info.errors.push({
                    path: filePath,
                    age: fileAge,
                    size: fileSize,
                    error: error
                });
            }

        } else {
            // If last modified time is older than the cutoff date, delete the file
            if (fileAge < cutoff) {

                try {
                    
                    fs.unlinkSync(filePath);

                    info.deleted.push({
                        path: filePath,
                        size: fileSize
                    });

                } catch (error) {
                    error = GenerateSafeError(error);
                    console.warn(`Error deleting file ${filePath}`, error);
                    info.errors.push({
                        path: filePath,
                        age: fileAge,
                        size: fileSize,
                        error: error
                    });

                }
            } //the file
        }

    }

    return info;

} module.exports.PruneAllFilesInDir = PruneAllFilesInDir;


function PruneEmptyFolder(dir) {
    var dirFiles = fs.readdirSync(dir);
    if (dirFiles.length == 0) {
        //delete the directory
        fs.rmdirSync(dir);
        // info.deleted.push({
        //     path: dir,
        //     size: 0
        // });
        return true;
    }

    return false;

} module.exports.PruneEmptyFolder = PruneEmptyFolder;

/**
 * Get's the latest JSON file in a specified folder
 * @param {*} folder The fully qualified path to the folder.
 * @returns {*} The fully qualified path to the file or false if no files were found.
 */
function getLatestJsonFile(folder) {
    var files = fs.readdirSync(folder)
      .filter(file => path.extname(file) === '.json')
      .sort((a, b) => b.localeCompare(a)); // Sort in descending order
  
    if (files.length > 0) {
      return path.join(folder, files[0]);
    }
  
    return false; // No .json files found in the directory
} module.exports.getLatestJsonFile = getLatestJsonFile;

/**
 * Adds a "/" if the folder name doesn't include one at the end
 * @param {*} folder The folder name
 * @returns the corrected folder name
 */
function correctFolder(folder) {
    //trim the folder

    if (folder == undefined) {
        folder = "";
    }

    if (folder != "") {
        folder = folder.trim();
        if (folder[folder.length - 1] != "/") {
            folder = folder + "/";
        }
    }
    return folder;
}

