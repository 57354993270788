/**
 * Converted to New Approach (json render)
 * 
 * Universe App Tools
 * Adds a footnote to the page.
 * 
 * Created by Justin K Kazmierczak.
 */


var namespace = "ua.navbar.item";
exports.namespace = namespace;

var jsonRender = require("../interface/jsonRender.js");
var uai = require("../interface/interface.js");
// var uae = require("./templates/ua.element.js");

var required = ["inner"]; 
exports.required = required;

exports.define = {
  namespace: namespace,
  title: "Enhanced Navigation Bar",
  description: "A beutiful navigation bar for your app. If you use the fixed-top class, it will automatically add margin to the top of the body (this may overide other settings) so it doesn't overlap the content.",
  fields: {
    src: {
      type: "uri",
      description: "The page or link to navigate to.",
      required: true
    },
    type: {
      type: "list",
      items: {
        "text": "Show a link as text only.",
        "icon": "Show a link as an icon with text.",
        "image": "Show a link as an image with text.",
        "big-image": "Show a link as text on the background of the image."
      },
    }, "items": {
      "type": "list of namespaces",
      "namespaces": ["ua.navbar.items"],
      "description": "The menu items to display on click."
    },
    title: {
      type: "string",
      description: "The title of the link."
    },
    icon: {
      type: "icon",
      description: "The icon to use for the link."
    },
    image: {
      type: "uri",
      description: "The image to use for the link."
    },
    hideText: {
      type: "boolean",
      description: "If the text should be hidden.",
      default: false
    },
    quickName: {
      type: "string",
      description: "The quick name of the link to display on the navbar when the menu is fully collapsed."
    },
    pin: {
      type: "boolean",
      description: "If the link should be pinned to the top of the expanded navbar menu.",
      default: false
    },
    ignorePin: {
      type: "boolean",
      description: "Used by the enhanced navbar (ua.navbar) to ignore a pin when the maximum number of pinned items is reached.",
      default: false
    },
    target: {
      type: "list",
      items: {
        "_self": "Open the link in the same tab.",
        "_blank": "Open the link in a new tab.",
        "_parent": "Open the link in the parent frame.",
        "_top": "Open the link in the full body of the window."
      },
    },
    active: {
      type: "boolean",
      description: "If the link should be active.",
      default: false
    }
  },
  // supportServer: false, 
  required: required
}

var navbarItemClass = "ua-navbar-item bg-blur-og-75-primary-hover rounded";
var bigImageAnchorColClass = "bg-blur-og-50-black-hover";
var bigImageAnchorClass = "bg-blur-black p-3 rounded ";
var bigImageClass = `${navbarItemClass} ua-navbar-big-image`;
// var imageAnchorClass = " bg-blur-black";

var pinnedColPerRow = 3;

/**
 * Returns a simple version of the navigation item, using just an a tag.
 * @param {*} options The options to render the simple navigation item.
 * @returns A ua/json object - requires rendering.
 */
exports.GetSimple = async function (options) {
  var anchor = {
    n: "a",
    c: "nav-link",
    href: options.src,
    i: options.title
  }

  if ("class" in options) {
    anchor.c += " " + options.class;
  }

  if ("hideText" in options && options.hideText) {
    anchor.i = "";
  }

  if ("target" in options) {
    anchor.target = options.target;
    // console.log("Adding Target...");
  }

  // get icon or image
  var img = false;

  if (options.type == "icon") {
    img = {
      n: "i",
      c: options.icon
    }
  }

  if (options.type == "image") {
    img = {
      n: "img",
      c: "img-fluid rounded",
      src: options.image
    }
  }

  if (img) {
    anchor.i = [img, " " + anchor.i];
  }

  return anchor;

}

/**
 * We'll add the footnote to the footnotes for the page, and output the corresponding number
 * @param {*} options The json element to render the footnote on.
 * @returns The final renderalbe object, dom or ua/json.
 */
exports.render = async function (options) {

  var holder = {};

  if (!("type" in options)) {
    //if image is defined
    if ("image" in options) {
      options.type = "image";
    } else if ("icon" in options) {
      options.type = "icon";
    } else {
      options.type = "text";
    }
  }

  var anchor = {
    n: "a",
    c: "text-white text-decoration-none text-decoration-underline-hover",
    href: options.src, 
    title: options.title,
    i: " " + options.title
  }

  if ("class" in options) {
    anchor.c += " " + options.class;
  }

  if ("hideText" in options && options.hideText) {
    anchor.i = "";
  }

  if ("target" in options) {
    anchor.target = options.target;
    // console.log("Adding Target...");
  }
 
  var anchorCol = {
    n: "div",
    c: "col text-start",
    i: [anchor]
  }

  // if (("pin" in options && options.pin)) {
  //   anchorCol.c += " pt-3";
  // } else {
  //   anchorCol.c += " text-start p-3";
  // }

  var img = {};


  var imageCol = {
    n: "div",
    c: "col",
    i: [
       
    ]
  };

  // if (!("pin" in options) || !options.pin) {
    imageCol.c = "col-3";
  // }

  if (options.type == "text") {
    imageCol = "";
  } else if (options.type == "icon") {

//     <svg class="bi" width="32" height="32" fill="currentColor">
//   <use xlink:href="bootstrap-icons.svg#heart-fill"/>
// </svg>
    //needs fiding
// img = {
    //   n: "svg",
    //   c: "img-fluid",
    //   fill: "currentColor", 
    //   i: [
    //     {
    //       n: "use",
    //       "xlink:href": uai.resolveURL(`@!/uat/bi/${options.icon}.svg`)
    //     }
    //   ]
    // }

    img  ={
      n: "img",
      c: "img-fluid",
      src: uai.resolveURL(`@!/uat/bi/${options.icon}.svg`),
      style: "filter: brightness(0) invert(1); width: 1.5rem; height: 1.5rem;"
    }

  } else if (options.type == "image") {
    img = {
      n: "img",
      c: "img-fluid rounded",
      src: options.image
    }
  }

  if (!(imageCol == "")) {
    imageCol.i.push(img);
  }

  
  if (options.type == "big-image") { 
    anchorCol.c += " " + bigImageAnchorColClass
    anchorCol.i[0].c += " " + bigImageAnchorClass;
    holder = {
      n: "div",
      c: bigImageClass,
      style: `background-image: url(${options.image}); background-size: cover; background-position: center; background-repeat: no-repeat;`,
      i: [
        anchorCol]
    }
  } else {
    holder = {
      n: "div",
      c: navbarItemClass + " p-2",
      i: [
        imageCol,
        anchorCol]
    }  
  }

  // if ("pin" in options && options.pin) {
  //   holder.c += " ua-navbar-item-pin";
  // }

  if ("items" in options) {
    var menu = createNavMenu(options.items);
    holder["ua-navbar-menu"] = menu.id;
    holder.i.push(menu);

    holder.c += " ua-navbar-item-controls-menu";

  }

  return jsonRender.render(holder, {
    PassthroughOptions: options
  }); 

}

function createNavMenu(items, inner = false) {

  var myid = uai.generateRandomID(9);

  //get all pinned items
  var pinned = [];
  var unpinned = [];

  for (var i = 0; i < items.length; i++) {
    var item = items[i];
    // if ("pin" in item && item.pin) {
    //   pinned.push(item);
    // } else {
      unpinned.push(item);
    // }
  }

  var menuItems = [];

  if (pinned.length > 0) {
    //for each pinned item wrap it in a col-4

    var pinnedItems = {
      "n": "div",
      "c": "row g-0 align-items-top p-3",
      "i": []
    }

    colRemainderNumber = pinned.length % pinnedColPerRow;
    colRemainderStart = pinned.length - colRemainderNumber;
    // console.log(`Col remainder: ${colRemainderNumber}`);

    for (var i = 0; i < pinned.length; i++) {
      var item = pinned[i];


      if (i >= colRemainderStart) {

        // console.log("The remainder item is", item);

        // item.c = item.c.replace("ua-navbar-item-pin", " ");
        // item.i[0].c = "col-2";

        if (colRemainderNumber == 1) {

        item.pin = false;
        var col = {
          n: "div",
          c: "d-block",
          i: [item]
        }
      } else {
        var col = {
          n: "div",
          c: "col",
          i: [item]
        }
      }

      } else {
      var col = {
        n: "div",
        c: `col-${12 / pinnedColPerRow}`,
        i: [item]
      }
    }

      //curColNum = curColNum + 1;
      pinnedItems.i.push(col);

    }

    menuItems.push({
      "n": "div",
      "c": "bg-25-white tiny-outline rounded",
      "i": [pinnedItems]
    });

  }

  var specialBorder = "";// {
  //   "n": "div",
  //   "c": "text-start",
  //   "i": {
  //     "n": "div",
  //     "c": "border-top border-white border-1 border-solid col-2",
  //   }
  // };

  var newUnpinned = [];

  //insert a copy of the special border before every unpinned item
  for (var i = 0; i < unpinned.length; i++) {
    var item = unpinned[i];
    if (i > 0) {
      newUnpinned.push(specialBorder);
    }
    newUnpinned.push(item);
  }

  if (unpinned.length > 0) {
    menuItems.push({
      "n": "div",
      "c": "row justify-content-center",
      "i": [ newUnpinned]
    });
  }

  var close = {
    "n": "button",
    "c": "btn btn-text-white text-hover-black rounded text-shadow ua-navbar-menu-close ua-navbar-item-controls-menu",
    "ua-navbar-menu": myid,
    "i": [
      {
        "n": "i",
        "c": "bi bi-x-circle",
        "i": "&nbsp;"
      }
    ]
  };

  var menuItemsArr = [menuItems];

  //if inner is not undefined or false
  if (inner) {
    menuItemsArr.push(inner);
  }

  var menu = {
    n: "nav",
    c: "ua-navbar-menu bg-blur-og-50-black",
    id: myid,
    i: [{
      n: "div",
      c: "holster tiny-outline bg-blur-black",
      i: [menuItemsArr]
    }, close]
  }; 

  return menu;

} exports.createNavMenu = createNavMenu;

//add a handler for clicking on a .ua-navbar-item element
document.addEventListener("click", function (event) {
  
  // console.log("Clicked on:", event.target);

    //does the element have a ua-navbar-menu attribute?
    // if (event.target.hasAttribute("ua-navbar-menu")) {
    if (event.target.matches(".ua-navbar-item-controls-menu") || event.target.closest(".ua-navbar-item-controls-menu")) {
      event.preventDefault();

      var uaNavBarItem = event.target.closest(".ua-navbar-item-controls-menu");
      var id = uaNavBarItem.getAttribute("ua-navbar-menu");
      var menu = document.getElementById(id);
      if (menu) {
        menu.classList.toggle("show");
        return;
      }

      // //does the event have a ua-navbar-menu-close class?
      // if (event.target.classList.contains("ua-navbar-menu-close")) {
      //   //get the menu
      //   var id = event.target.getAttribute("ua-navbar-menu");
      //   var menu = document.getElementById(id);
      //   if (menu) {
      //     menu.classList.remove("show");
      //   } else {
      //     console.error(`The menu with id ${id} does not exist.`);
      //   }
      //   return;
      // }

      // //get the menu
      // var id = event.target.getAttribute("ua-navbar-menu");
      // var menu = document.getElementById(id);
      // if (menu) {
      //   menu.classList.add("show");
      //   return;
      // }
    }

  //check if the element is a .ua-navbar-item element or is a child of a .ua-navbar-item element
  if (event.target.matches(".ua-navbar-item") || event.target.closest(".ua-navbar-item")) {

    //if it's an anchor tag, don't prevent the default action
    if (event.target.matches(".ua-navbar-item a") || event.target.closest(".ua-navbar-item a")) {
      return;
    }

    uaNavBarItem = event.target.closest(".ua-navbar-item");

    //prevent the default action
    event.preventDefault();
    
    //get the anchor element
    var anchor = uaNavBarItem.querySelector("a");
    //check if the anchor exists
    if (anchor) {
      //get the href
      var href = anchor.getAttribute("href");

      //trigger the click event on the anchor
      anchor.click();

         //hide all menus
         var menus = document.querySelectorAll(".ua-navbar-menu");
         menus.forEach(menu => {
           menu.classList.remove("show");
         });
         
      // //check if the href exists
      // if (href) {
      //   //navigate to the href
      //   window.location.href = href;
      //   return;
      // }
    } 

  }

  //if an event target is ".ua-navbar-menu" but not any of it's children
  // if (event.target.matches(".ua-navbar-menu") && !event.target.closest(".ua-navbar-menu")) {
    // console.log("Clicked on the menu but not any of it's children.", event.target);
    // event.target.classList.remove("show");
  // }

  if (event.target.matches(".ua-navbar-menu")) {
    // console.log("Clicked on the menu.", event.target);
    event.target.classList.remove("show");
  }

});


//when the page has completed loading highlight the anchor tag with the window.location 
document.addEventListener("DOMContentLoaded", function (event) {
  //get the window.location

  try {
    var url = window.location.href;
    
    //clear previous nav-link active classes
    var activeLinks = document.querySelectorAll(".nav-link.active");
    activeLinks.forEach(link => {
      link.classList.remove("active");
    });

    var anchor = FindNavLinkByHREF(url);
    if (anchor) {
      anchor.classList.add("active");
    }

  } catch (error) {
    
  }
  

});

/**
 * Returns the first nav-link with the href provided.
 * @param {*} href The href to search for.
 * @returns The nav-link with the href, or false if it does not exist.
 */
function FindNavLinkByHREF(href) {
  var anchor = document.querySelector(`a[href="${href}"]`);
  if (anchor) {
    if (anchor.classList.contains("nav-link")) {
      return anchor;
    }
  }

  href = href.split("#").pop();
  anchor = document.querySelector(`a[href="#${href}"]`);
  if (anchor) {
    if (anchor.classList.contains("nav-link")) {
      return anchor;
    }
  }

  return false;
}