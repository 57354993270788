var jsonRender = require("../interface/jsonRender.js");
var uai = require("../interface/interface.js");
var bootstrap = require("bootstrap");

var define = {
  namespace: "ua.radio.accordion",
  title: "Accordion Control",
  description: "A collapsible accordion control for selecting options.",
  control: true,
  fields: {
    "inner": {
      type: "array",
      description: "The items of the accordion, each with a title, description, and optional icon.",
      required: true,
      item: {
        type: "object",
        properties: {
          "title": { type: "string", required: true },
          "inner": { type: "object", required: true },
          "icon": { type: "string", required: false },
          "value": { type: "string", required: true }
        }
      }
    }, "name": {
      type: "string",
      description: "The name of the accordion control.",
      required: true
    }
  }
}; module.exports.define = define;

async function render(options) {

  var id = uai.generateRandomID(12);

  var elements = options.inner.map((item, index) => {
    var radioId = `radio-${uai.generateRandomID(12)}`;
    var randomId = `collapse-${uai.generateRandomID(12)}`;
    var headerId = `heading-${uai.generateRandomID(12)}`;
    return {
      "n": "div",
      "c": "accordion-item",
      "i": [
        {
          "n": "h2",
          "c": "accordion-header",
          "id": headerId,
          "i": {
            "n": "button",
            "c": "accordion-button collapsed",
            "type": "button",
            "data-bs-toggle": "collapse",
            "data-bs-target": `#${randomId}`,
            "aria-expanded": "false",
            "aria-controls": randomId,
            "data-accordion-id": id,
            "i": [
              {
                "n": "input",
                "c": "form-check-input",
                  "type": "radio",
                  "name": "radioGroup",
                  "value": item.value,
                  "id": radioId,
                  "data-collapse-target": randomId
              },
              {
                "n": "div",
                "c": "p-3 fs-4",
                "i": {
                  "n": "label",
                  "c": "form-check-label",
                  "for": radioId,
                  "radioid": radioId,
                  "i": item.title,
                  "data-accordion-id": id
                }
              }
            ]
          }
        },
        {
          "n": "div",
          "id": randomId,
          "c": "accordion-collapse collapse",
          "aria-labelledby": headerId,
          "i": {
            "n": "div",
            "c": "accordion-body",
            "i": item.inner
          }
        }
      ]
    };
  });

  var accordion = {
    "n": "div",
    "c": "accordion",
    "id": id,
    "name": options.name,
    "i": elements
  };

  return await jsonRender.render(accordion);
}; module.exports.render = render;


//set up a click event to collapse other accordion-items, and select the radio button when the accordion button is clicked
document.addEventListener('click', function (event) {

  try {
    

    if (event.target.matches('.accordion-button')) {
      var radio = event.target.querySelector('input[type="radio"]');
      if (radio) {
        radio.checked = true;
      }
      var accordionId = event.target.getAttribute('data-accordion-id');
      var accordion = document.getElementById(accordionId);
      var items = accordion.querySelectorAll('.accordion-item');
      items.forEach(item => {

        try {
          var collapse = bootstrap.Collapse.getInstance(item.querySelector('.accordion-collapse'));

          if (collapse) {
            if (item !== event.target.closest('.accordion-item')) {
              // item.querySelector('.accordion-button').classList.add('collapsed');
              //use bootstrap collapse to hide the accordion body
            
              collapse.hide();
    
            } else {
              // item.querySelector('.accordion-button').classList.remove('collapsed');
            }
          }
        } catch (error) {
          
        }
      

      });
    }

    //if it's one of the labels or radio buttons as well
    if (event.target.matches('.form-check-label') || event.target.matches('input[type="radio"]')) {
      var radio = event.target.closest('.form-check-label').querySelector('input[type="radio"]');
      if (radio) {
        radio.checked = true;
      }
      var accordionId = event.target.getAttribute('data-accordion-id');
      var accordion = document.getElementById(accordionId);
      var items = accordion.querySelectorAll('.accordion-item');
      items.forEach(item => {
        try {
          var collapse = bootstrap.Collapse.getInstance(item.querySelector('.accordion-collapse'));

          if (collapse) {
            if (item !== event.target.closest('.accordion-item')) {
              // item.querySelector('.accordion-button').classList.add('collapsed');
              //use bootstrap collapse to hide the accordion body
              collapse.hide();
            } else {
              // item.querySelector('.accordion-button').classList.remove('collapsed');
            }
          }
        } catch (error) {
          
        }
      });
    }

  } catch (error) {
      
  }


});

/**
 * Saves the state of the accordion control.
 * @param {string} name The name of the control.
 * @param {HTMLElement} control The accordion control element.
 * @param {Object} repo The repository object to store the save results.
 * @returns {Object} The updated repository object with the save results.
 */
async function save(name, control, repo) {
  var selectedInput = control.querySelector('input[type="radio"]:checked');

  if (!selectedInput) {
      repo.success = false;
      repo.errors.push({
          type: "validation",
          message: "No option selected."
      });
  } else {
      repo.success = true;
      repo.data = {
          value: selectedInput.value
      };
  }

  return repo;
}

/**
* Loads the state into the accordion control.
* @param {string} name The name of the control.
* @param {HTMLElement} control The accordion control element.
* @param {Object} data The data to load into the control.
*/
async function load(name, control, data) {
  if (data && data.value) {
      var inputToSelect = control.querySelector(`input[type="radio"][value="${data.value}"]`);
      if (inputToSelect) {
          inputToSelect.checked = true;
      }
  }
}

module.exports.save = save;
module.exports.load = load;
