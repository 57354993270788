/**
 * Converted to New Approach (json render)
 * 
 * Universe App Tools
 * Adds a footnote to the page.
 * 
 * Created by Justin K Kazmierczak.
 */
var namespace = "pages.preview";
exports.namespace = namespace;

var f = require("../scripts/f.js");
var jsonRender = require("../interface/jsonRender.js");

// var fs = require("fs");
// var resolve = require("../../../uam/functions/resolve.js").function;
// var resolve = require("../../../")

// var uai = require("../interface/interface.js");
// const { l } = require("../../../uam/sanitize.js");

var describeAccountingDate = require("../../../uam/functions/describeAccountingDate.js").function;

var required = [];
exports.required = required;

exports.define = {
    namespace: namespace,
    title: "Universe Pages Preview Preview",
    description: "Generates a preview for a pages entry.",
    fields: {
        "previewTitle": {
          type: "string",
          description: "The title of the preview.",
          defaults: "Preview"
        }, "title": {
          type: "string",
          description: "The title of the blog."
        }, "description": {
            type: "string",
            description: "The description of the blog."
        }, "image": {
            type: "uri",
            description: "The image of the blog."
        }, "inner": {
            type: "inner",
            description: "The inner content of the blog."
        }, "url": {
            type: "uri",
            description: "The url of the blog."
        }, "on": {
            type: "pages.on",
            description: "The dates of the blog."
        }
    },
    required: required
}

/**
 * We'll add the footnote to the footnotes for the page, and output the corresponding number
 * @param {*} ele The json element to render the footnote on.
 * @returns The final renderalbe object, dom or ua/json.
 */
exports.render = async function (options) {

    // var entry = false;
    // var extraPath = "";

    // if ("src" in options) {
    //     var json = false;
    //     if ("UATisServer" in window && window.UATisServer) {



    //       console.info("Server side blog preview not implemented yet.", options);

    //       var onlyClientSideRender = {
    //         "n": "json",
    //         "type": "ua/interface",
    //         // "render": "no-server",
    //         "i": JSON.stringify(options, null, 0) + ""
    //       };

    //       // console.info("Only client side render", onlyClientSideRender);
    //       return await jsonRender.render(onlyClientSideRender);
            
    //         // throw new Error("Server side blog preview not implemented yet.");
            
    //     } else {

    //         var blogUri = [location.protocol, '//', location.host, location.pathname]
    //         blogUri = blogUri.join('');
    //         blogUri = blogUri + options.src;
    //         //blogUri = blogUri.replace("/../", "/");

    //         var response = false;
    //         try {
    //             // fetch synchronously

    //             var xmlHttp = new XMLHttpRequest();
    //             xmlHttp.open("GET", blogUri, false); // false for synchronous request
    //             xmlHttp.send(null);
    //             response = xmlHttp.responseText;
    //             entry = JSON.parse(response);

    //             // json = fetch(window.location.href + options.src, {
    //             //     method: 'GET',
    //             //     headers: {
    //             //         'Content-Type': 'application/json',
    //             //         'Accept': 'application/json'//,
    //             //         //'UA-Version': config.version
    //             //     }
    //             // });
    //             // entry = json.json();

    //             //get the folder path
    //             var path = options.src.split("/");
    //             path.pop();
    //             extraPath = path.join("/") + "/";

    //         } catch (error) {
    //             console.error(`Error fetching blog entry at ${blogUri}.`, {
    //                 blogUri: blogUri,
    //                 error: error,
    //                 response: response
    //             });
    //             throw error;
    //         }

    //     }
    // } else {
    //     entry = options;
    // }

    // console.log("Entry", entry);

    var entry = options;

    var previewTitle = "Preview";

    if ("previewTitle" in options) {
      previewTitle = options.previewTitle;
    }

    try {
     
      entry.on.publishedDescribed = describeAccountingDate(entry.on.published);

    } catch (error) {
      
      console.error("Entry Failed", {
        entry: entry,
        on: entry.on,
        error: f.GenerateSafeError(error)
      });
    
      throw error;
      
    }

    if (!("title" in options)) {
        options.title = GetPublishedOnDescription(entry.on.publishedDescribed);
    }


    var blog = [{
        "n": "div",
        "class": "col-12 d-none d-lg-flex bg-75-dark tiny-outline box-shadow",
        "inner": [
          {
            "n": "div",
            "class": "col-4 d-flex box-shadow",
            "style": `background: url('${entry.image}') no-repeat center center;background-size: cover;`
          },
          {
            "n": "div",
            "class": "rounded d-flex align-items-center rounded", 
            "inner": [
              {
                "n": "div",
                "class": "",
                "style": "",
                "inner": [
                  {
                    "n": "p",
                    "style": "padding-left:1.5rem; padding-bottom:0px; margin:0px;",
                    "class": "fs-6 text-primary",
                    "inner": [
                      previewTitle
                    ]
                  },
                  {
                    "n": "div",
                    "style": "border-bottom: var(--bs-blue) 1px solid; width: 35%; margin-left:1.5rem;"
                  },
                  {
                    "n": "div",
                    "class": "p-4",
                    "style": " padding-top: 0rem !important;",
                    "inner": [
                      {
                        "n": "h1",
                        "class": "text-start text-uppercase text-white break-letter",
                        "style": "font-size: 2.2em; font-weight:800; line-height: 1.5em; padding-top: 1rem;",
                        "inner": [
                          entry.title
                        ]
                      },
                      {
                        "n": "div",
                        "style": "padding-top:0rem; padding-bottom: 1rem;",
                        "inner": [
                          {
                            "n": "div",
                            "style": "border-top: .5rem var(--bs-blue) solid;",
                            "class": "w-50",
                          }
                        ]
                      }, 
                      {
                        "n": "blockquote",
                        "class": "blockquote text-left",
                        "i": [{
                            n: "p",
                            c: "p-2",
                            inner: options.description
                        }, {
                          "n": "div",
                          "s": "m-5",
                          "c": "border-bottom: 2px solid var(--bs-blue);"
                        }]
                      }
                      // ,
                      // entry.inner
                    ]
                  },
                  {
                    "n": "div",
                    "class": "row p-3 justify-content-end",
                    "inner": [
                      {
                        "n": "a",
                        "class": "btn col-6 btn-primary btn-large btn-hover-white box-shadow",
                        "href": entry.url,
                        "inner": [
                          "Read More"
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }, {
        "n": "div",
        "class": "col-12 d-flex d-lg-none bg-75-dark tiny-outline box-shadow",
        "inner": [
          {
            "n": "div",
            "class": "rounded d-block align-items-center rounded",
            "inner": [
              // {
              //   "n": "img",
              //   "class": "image-shadow image-fill box-shadow",
              //   "src": entry.image
              // }, 
              {
                "n": "div",
                "onclick": `window.location.href = '${entry.url}';`,
                "class": "d-block align-items-center p-3 text-shadow box-shadow ",
                "style": `background: url('${entry.image}') no-repeat center center;background-size: cover;padding-bottom: 10rem !important;`,
                "i": [
                  {
                    "n": "h1",
                    "class": "text-start text-uppercase text-white break-letter",
                    "style": "font-size: 2.2em; font-weight:800; line-height: 1.5em;",
                    "inner": [{
                      "n": "span",
                      "class": "bg-75-black",
                      "i": entry.title
                    }]
                  },
                  {
                    "n": "div",
                    "style": "padding-top:0rem; padding-bottom: 4rem;",
                    "inner": [
                      {
                        "n": "div",
                        "style": "border-top: .5rem var(--bs-blue) solid;",
                        "class": "w-50",
                      }
                    ]
                  },
                ]
              }, {
                "n": "div",
                "class": "d-block",
                "inner": [
                  {
                    "n": "p",
                    "style": "padding-left:1.5rem; padding-bottom:0px; margin:0px;",
                    "class": "fs-6 text-primary",
                    "inner": {
                      "n": "a",
                      "href": entry.url,
                      "i": previewTitle
                    }
                  },
                  // {
                  //   "n": "div",
                  //   "style": "border-bottom: var(--bs-blue) 1px solid; width: 35%; margin-left:1.5rem;"
                  // },
                  {
                    "n": "div",
                    "class": "p-4",
                    "style": "padding-top: 0rem !important;",
                    "inner": [
                      {
                        "n": "blockquote",
                        "class": "blockquote text-left",
                        "i": [{
                          n: "p",
                          c: "p-2",
                          inner: options.description
                        }, {
                          "n": "div",
                          "s": "m-5",
                          "c": "border-bottom: 2px solid var(--bs-blue);"
                        }]
                    }
                    //, entry.inner
                   ]
                  }
                ]
              }, {
                "n": "div",
                "class": "row p-3 justify-content-end",
                "inner": [
                  {
                    "n": "a",
                    "class": "btn col-6 btn-primary btn-large btn-hover-white box-shadow",
                    "href": entry.url,
                    "inner": [
                      "Read More"
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ];

    return await jsonRender.render(blog, {
        PassthroughOptions: options
    }, namespace);

};

function GetPublishedOnDescription(published) {

  //convert YYYYMMDD or YYYYMMDDHHMM to date time

  var defMessage = "Published on " + published;

  var date = new Date(published);

  if (date == false) {
    return defMessage;
  }

  //get the difference between now and the date
  var diff = new Date() - date;

  //if the difference is less than 1 day, return "today"
  if (diff < 86400000) {
    return "Published today";
  }

  //if the difference is less than 2 days, return "yesterday"
  if (diff < 172800000) {
    return "Published yesterday";
  }

  //if the difference is less than 7 days, return "x days ago"
  if (diff < 604800000) {
    return "Published " + Math.floor(diff / 86400000) + " days ago";
  }

  //if the difference is less than 2 weeks, return "last week"
  if (diff < 1209600000) {
    return "Published last week";
  }

  //if the difference is less than 1 month, return "x weeks ago"
  if (diff < 2592000000) {
    return "Published " + Math.floor(diff / 604800000) + " weeks ago";
  }

  //if the difference is less than 2 months, return "last month"
  if (diff < 5184000000) {
    return "Published last month";
  }

  //if the difference is less than 1 year, return "x months ago"
  if (diff < 31536000000) {
    return "Published " + Math.floor(diff / 2592000000) + " months ago";
  }

  return defMessage;
}

function getEntryBySource(src) {
    //fetch the file contents client side

}
